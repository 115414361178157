<template>
	<div id="app">
		<Integrations />
		<RouterView />
		<Lightbox v-if="isLightboxOpen" />
	</div>
</template>

<script>
import Lightbox from '@zyro-inc/site-modules/components/lightbox/Lightbox.vue';
import { useLightbox } from '@zyro-inc/site-modules/components/lightbox/useLightbox';
import Integrations from '@/components/metas/integrations/Integrations.vue';

import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		Lightbox,
		Integrations,
	},

	setup() {
		const { isLightboxOpen } = useLightbox();

		return {
			isLightboxOpen,
		};
	},
});
</script>
